function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

(function($) {
    var fadeTime = 300;
    $(document).ready(function() {
        function checkCookie(cookie) {
            var showCookie = getCookie(cookie);
            if (showCookie !== "") {} else {
                $("#cookie").fadeIn(fadeTime);
            }
        }
        checkCookie("showcookie");
        $("#cookie button").on("click", function() {
            setCookie("showcookie", "absoultinte", 365);
        });
    });
})(jQuery);