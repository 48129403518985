(function ($) {
    'use_strict';
    
    /**
     * stickyToFixed
     * @param {type} options
     * @returns {sticky_L1.$.fn.stickyToFixed.self|$}
     * @use $(element).stickyToFixed({element to offset: default footer element});
     * @author Hampus Alstermo
     * @version 1.0
     */
    
    $.fn.stickyToFixed = function (options) {
        var self = $(this);
        
        self.dummy = $('<div/>',{
               "class" : "dummy" 
            });

        self.options = $.extend({
            element : $(".master-slider-parent")   
        }, options);
        
        self.init = function(){
          $(window).on("scroll load",self.window_scroll);  
        }; 
        
        self.window_scroll = function(event){
            if(self.isElementVisible()){
                self.addClass("static");
                
                self.removeDummy();
            }else{
                self.removeClass("static");
                self.addDummy();
            }             
        };
        
        self.addDummy = function(){
            self.after(self.dummy);
            self.dummy.css({"height" : self.outerHeight()});
        };
        
        self.removeDummy = function(){
            self.dummy.remove();
        };
        
        self.isElementVisible = function(){
            
            if(self.options.element.length === 0) return false; 
            
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();
            var elemTop = self.options.element.offset().top;
            var elemBottom = elemTop + self.options.element.height();
            return (elemBottom >= docViewTop);   
        };
        
        self.init();
        
        return self;
    };

    $(window).ready(function(){
       //$("nav").stickyToFixed();   
    });

})(jQuery,window,document);