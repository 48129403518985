(function($) {
    'use_strict';



    $(document).ready(function() {

        // $(window).on('beforeunload', function() {
        //     $('html,body').fadeOut(500, function() {

        //     });
        // });

        $(window).on('hashchange load', function() {
            var hash = location.hash;
            if (hash != '') {
                var top = $(hash).offset().top;
                top = (top - $("#main-nav").outerHeight()) + 1;
                $(window).scrollTop(top);
            }
            return false;
        });

        $("a[href*=\\#]").on('click', function(e) {


            var btn = $(this);

            // store hash
            var hash = this.hash;

            //Dont scroll if the element dosent exist.
            if ($(hash).length === 0) {
                return true;
            }

            //$(".gf-cart").slideUp();



            // prevent default anchor click behavior
            e.preventDefault();

            if (btn.parent().hasClass("menu-item") && $(window).outerWidth() < 860) {
                $("button.navbar-toggle").trigger("click");
            }

            var top = $(hash).offset().top;

            if ($(hash).hasClass("bottom") && $(window).outerWidth() > 859) {
                top = $(".wrap").outerHeight() + $(".wrap").offset().top;
            }

            top = top - $("#main-nav").outerHeight();

            // animate
            $('html, body').stop().animate({
                scrollTop: top
            }, 1500, function() {
                // when done, add hash to url
                // (default click behaviour)
                window.location.hash = hash;
            });
        });
    });

})(jQuery, document, window);