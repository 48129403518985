(function($) {


    var scrollColor = function(opts) {

        self = this;
        self.opts = $.extend({
            nav: false,
            navTarget: false,
            sections: false,
            colors: []
        }, opts);

        self.init = function() {

            self.setColors();
            $(window).on("load scroll", self.changeColor);


        };

        self.changeColor = function(evt) {
            var window = $(this);
            var headerHeight = self.opts.nav.outerHeight();
            var parentNav = self.opts.nav.parents(self.opts.navTarget);


            self.opts.sections.each(function(index) {
                var section = $(this);
                if (window.scrollTop() + (headerHeight) > section.offset().top) {
                    parentNav.removeClass("hasWhite");

                    if (self.opts.colors[index] === "#ffffff") {
                        parentNav.addClass("hasWhite");
                    }

                    if (section.attr("id") !== "hem") {
                        if (section.hasClass("light_menu")) {
                            parentNav.removeClass("dark_menu");
                            parentNav.addClass("light_menu");
                        } else {
                            parentNav.addClass("dark_menu");
                            parentNav.removeClass("light_menu");
                        }
                    } else {
                        parentNav.removeClass("light_menu");
                        parentNav.removeClass("dark_menu");
                    }



                    self.opts.nav.css('backgroundColor', self.opts.colors[index]);

                }
            });


        }

        self.setColors = function() {

            self.opts.sections.each(function() {

                var elm = $(this);
                var colorelement = elm.find(".changeColor");
                var color = colorelement.css('backgroundColor');

                self.opts.colors.push(self.getBgColor(color));

            });

        };

        self.getBgColor = function(colorval) {
            var parts = colorval.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
            delete(parts[0]);
            for (var i = 1; i <= 3; ++i) {
                parts[i] = parseInt(parts[i]).toString(16);
                if (parts[i].length == 1)
                    parts[i] = '0' + parts[i];
            }
            color = '#' + parts.join('');
            return color;
        }

        self.init();

        return self;

    };

    // var sc = new scrollColor(
    //         {
    //             nav: $("body").find("#main-nav > .bg"),
    //             navTarget: "nav#main-nav",
    //             sections: $("section.gf") 
    //         }
    // );

})(jQuery, window, document);