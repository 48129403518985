(function ($) {
    "use strict";
    $(document).ready(function () {

        $(".logoslider").owlCarousel({
            margin: 30,
            loop: false,
            autoWidth: false,
            items: 3,
            nav: true,
            dots: false,
            navText: ['<i class="fa fa-angle-left fa-4x"></i>', '<i class="fa fa-angle-right fa-4x"></i>'],
            responsive: {
                // breakpoint from 0 up
                0: {
                    items: 1,
                    autoWidth: false
                }, 
                // breakpoint from 480 up
                860: {
                    items: 3, 
                    autoWidth: false,
                },
                // breakpoint from 768 up 
                992: {
                    items: 4
                }
            }
        });
    });
})(jQuery, window, document);   