(function($) {

    var sameheight = function() {
        var self = this;
        self.window = $(window);

        self.init = function() {

            self.window.on("load", function() {
                self.window.on("resize", self.make_same_height).trigger("resize");
            });

        };

        self.make_same_height = function(event) {


            if (!self.do_scroll(self.window))
                return false;

            $("body").find(".sameheight").each(function() {

                var element = $(this);
                if (element.next("div").length > 0) {
                    element.css({
                        "height": element.next("div").outerHeight()
                    });
                    element.find("div").first().css({
                        "height": element.next("div").outerHeight()
                    });
                }

            });

        };

        self.width = 0;
        self.height = 0;
        self.do_scroll = function(window) {

            if (window.outerWidth() !== self.width && window.outerWidth() >= 859) {
                self.width = window.outerWidth();
                return true;
            }

            return false;
        };

        self.init();
        return self;
    };

    $(document).ready(function() {
        var sh = new sameheight();
    });



    var contact = function() {

        this.init = function() {
            $(window).on('load resize', this.resizeContact);
        }

        this.resizeContact = function(e) {

            $(".single-contact").css({ height: 'auto' });

            if ($(this).outerWidth() > 767) return false;

            var height = 0;


            $(".center-col .single-contact").each(function() {

                var contact = $(this);

                if (contact.outerHeight() > height) {
                    height = contact.outerHeight();
                }

            });

            $(".center-col .single-contact").css({ height: height });

        }

        this.init();
        return this;
    }

    $(window).on('load', function(e) {
        new contact();
    });


})(jQuery);