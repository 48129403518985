(function ($) {

    var facebook_images = function () {

        var self = this;

        self.init = function () {

            if ($(".f-item").length > 0) {
                $(window).on("resize load", self.resize);
            }

        };

        self.resize = function (event) {
            var width = $("body").find(".f-item").first().outerWidth();
            $("body").find(".f-item").css({height: width});
        };

        self.init(); 

    };

    $(document).ready(function () {
        var fi = new facebook_images();
    }); 

})(jQuery,window,document);