(function($) {
    'use strict';


    $.fn.fixedFooter = function(opts) {

        var s = $(this);
        s.opts = $.extend({ fakeElement: false }, opts);

        s.init = function() {
            $(window).on("load resize", s.resize);
        };

        s.resize = function() {
            s.opts.fakeElement.css({ height: s.outerHeight() });
        };

        s.init();

        return s;
    };

    $.fn.footerBottom = function(opts) {
        var s = $(this);

        s.init = function() {
            $(window).on("load resize", s.resize);
        };

        s.resize = function() {

            var n = $("#main-nav").outerHeight();
            var f = $(".main-footer").outerHeight();

            s.css({ minHeight: $(window).height() - (n + f - 35) });
        };

        s.init();

    };

    $(document).ready(function() {

        $(".wrap").footerBottom();

    });

})(jQuery, window, document);