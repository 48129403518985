
(function ($) {

    var extend_slider = function () {

        var self = this;
        self.width = 0;
        self.height = 0;
        self.window = $(window);
        self.loaded = false;

        self.init = function () {
            
            self.window.on("load",function(){
               self.loaded = true; 
               self.window.on("ms_resize_fn", self.resize);  
               self.resize();  
            });
            
        };

        self.resize = function (evt) {
 
            if (self.loaded && $('.ms-slide,.ms-view').length > 0 && self.do_scroll(self.window)) {
                    
                var wh = self.window.outerHeight();

                var wpadminbar = $("#wpadminbar").length > 0 ? $("#wpadminbar").outerHeight() : 0;
                var menuHeight = $("#main-nav").outerHeight() + wpadminbar;

                wh = wh - menuHeight;

                $('.master-slider,.ms-slide,.ms-slide-bgcont img,.ms-basic-view').css({"height": wh,"margin-top":0,"min-height": wh});
                $('.ms-info').css({"margin-top": (wh / 2) - (($('.ms-info').outerHeight() + 100) / 2)});
    
            }
 
        };


        self.width = 0;
        self.height = 0;
        self.do_scroll = function (window) {

            if (window.outerWidth() !== self.width) {
                self.width = window.outerWidth();
                return true;
            }

            return false;
        };

 
        self.init();
        return self;
    };

    $(document).ready(function () {
        var es = new extend_slider();
    });


})(jQuery,window,document); 