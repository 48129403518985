(function($) {

    var mobile_menu = function() {
        var s = this;
        s.breakPoint = 767;
        s.windowWidth = 0;
        s.windowHeight = 0;
        s.element = $("#expand-main-nav");
        s.button = $("button.navbar-toggle");
        s.intraWebMenu = $('.sm-menu');
        s.visible = false;

        s.init = function() {
            $(window).on("resize load scroll touchmove", s.resize);
            s.button.on('click', s.showMenu);

            s.element.on('click', 'li.pre-menu', s.hideMenu);


            s.addIntraweb();
            s.addCloseBtn();
        };

        s.ulClick = function(event) {
            event.stopPropagation();
            event.preventDefault();
            console.log(event);
        };

        s.aClick = function(event) {
            return true;
        };

        s.showMenu = function(e) {
            s.resize(e);
            s.button.removeClass("collapsed out");
            s.element.addClass("in");
        };

        s.hideMenu = function(e) {
            s.element.removeClass("in").addClass("out");
            s.button.addClass("collapsed");
            s.visible = false;
            setTimeout(function() {
                s.element.removeClass("out");
            }, 500);
        };

        s.resize = function(event) {
            s.windowWidth = $(window).outerWidth();
            s.windowHeight = $(window).outerHeight();
            s.visible = true;

            if (s.windowWidth <= s.breakPoint) {
                if (s.visible === true) {
                    height = (typeof window.outerHeight != 'undefined') ? Math.max(window.outerHeight, $(window).height()) : $(window).height();
                    s.element.css({ height: height });
                }

            } else if (event.type !== "scroll") {
                s.element.css({ height: 'auto' });
            }
        };


        /**
         * Adds the intraweb menu without phonenumber to mobile menu
         */
        s.addIntraweb = function() {
            var menuItems = [];

            s.intraWebMenu.find('li').each(function() {
                var $this = $(this);

                if (!$this.hasClass('separator') || $this.hasClass('phone')) {
                    // Create a copy that we can alter
                    // without making changes to the original

                    var $copy = $($this[0].outerHTML);

                    $copy.addClass('visible-xs smaller');
                    menuItems.push($copy[0].outerHTML);
                }
            });

            s.element.find('ul').append(menuItems);
        };



        /**
         * Adds the close button on the open mobile menu
         */
        s.addCloseBtn = function() {
            var closeIcon = '<div class="mobile-close"><span></span><span></span></div>';
            var closeBtn = '<li class="pre-menu visible-xs"> <a href="#">' + closeIcon + 'Stäng </a></li>';

            s.element.find('ul').prepend(closeBtn);
        };


        s.init();
        return s;
    };

    new mobile_menu();

})(jQuery);